import { PaymentProviderName } from '@esp/payments/types-payment';

export const createPaymentMethodInfo = (prev: object, curr: string) => {
  return {
    ...prev,
    ...(paymentMethodInfoMap[curr as keyof typeof paymentMethodInfoMap]
      ? {
          [curr]:
            paymentMethodInfoMap[curr as keyof typeof paymentMethodInfoMap],
        }
      : {}),
  };
};

export const getProviderNameToDisplay = (name?: string) => {
  return name === PaymentProviderName.Authorizenet ? 'Authorize.Net' : name;
};

export const paymentMethodInfoMap = {
  VisaAcceptance: {
    Logo: '/assets/cosmos/images/icons/visa.png',
    Name: 'Visa',
    Id: 'visa',
  },
  MastercardAcceptance: {
    Logo: '/assets/cosmos/images/icons/mastercard.png',
    Name: 'Mastercard',
    Id: 'mastercard',
  },
  DiscoverAcceptance: {
    Logo: '/assets/cosmos/images/icons/discover.png',
    Name: 'Discover',
    Id: 'discover',
  },
  AmericanExpressAcceptance: {
    Logo: '/assets/cosmos/images/icons/americanexpress.png',
    Name: 'American Express',
    Id: 'americanExpress',
  },
  ElectronicFundsTransferAcceptance: {
    Logo: '/assets/cosmos/images/icons/echeck.png',
    Name: 'Electronic Fund Transfer',
    Id: 'electronicFundTransfer',
  },
} as const;
