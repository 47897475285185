import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, of } from 'rxjs';

import { ConfigService } from '@cosmos/config';
import type { Address } from '@cosmos/types-party';
import {
  OrderEmailTemplateType,
  type AllowEditOrderNumberSetting,
  type CurrencyConversion,
  type CurrencyPreferences,
  type DefaultTaskSetting,
  type Lookup,
  type NotificationSetting,
  type OrderEmailMergedField,
  type OrderEmailTemplate,
  type OrderStatusType,
  type Setting,
  type SettingCode,
  type TenantOrderNumber,
} from '@esp/settings/types';

import type { TenantValidationModel } from '../models';
import type { CustomTenantValidationModel } from '../models/custom-document-requirements-settings.model';

export type EmailTemplates = Record<OrderEmailTemplateType, OrderEmailTemplate>;

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  private _url = 'settings';

  private get _uri() {
    const espServiceApiUrl: string =
      this._configService.get('espServiceApiUrl');
    return `${espServiceApiUrl}/${this._url}`;
  }

  private _cachedMergedFields: Record<
    OrderEmailTemplateType,
    OrderEmailMergedField[]
  > | null = null;

  constructor(
    private _configService: ConfigService,
    private _http: HttpClient
  ) {}

  settings(): Observable<Setting[]> {
    return this._http.get<Setting[]>(this._uri);
  }

  updateSetting(code: SettingCode, setting: Setting): Observable<Setting> {
    return this._http.put<Setting>(`${this._uri}/${code}`, setting);
  }

  updateSettingBulk(setting: Setting[]): Observable<Setting[]> {
    return this._http.put<Setting[]>(`${this._uri}/bulk`, setting);
  }

  thirdPartyOptions(): Observable<Lookup[]> {
    return this._http.get<Lookup[]>(`${this._uri}/thirdpartyoptions`);
  }

  capabilities(): Observable<string[]> {
    return this._http.get<string[]>(`${this._uri}/capabilities`);
  }

  capability(
    name: string,
    type: string,
    companyId?: string
  ): Observable<boolean> {
    let url = `${this._uri}/capabilities/${name}/${type}`;

    if (companyId) {
      url += `/${companyId}`;
    }

    return this._http.get<boolean>(url);
  }

  orderNumber(): Observable<TenantOrderNumber> {
    return this._http.get<TenantOrderNumber>(`${this._uri}/ordernumber`);
  }

  updateOrderNumber(params: TenantOrderNumber): Observable<TenantOrderNumber> {
    return this._http.put<TenantOrderNumber>(
      `${this._uri}/ordernumber`,
      params
    );
  }

  currencyPreferences(): Observable<CurrencyPreferences> {
    return this._http.get<CurrencyPreferences>(
      `${this._uri}/currencypreferences`
    );
  }

  currencyConversionRate(
    sourceCode: string,
    targetCode: string
  ): Observable<CurrencyConversion> {
    return this._http.get<CurrencyConversion>(
      `${this._uri}/currency/${sourceCode}/conversionto/${targetCode}`
    );
  }

  updateCurrencyConversionRate(
    sourceCode: string,
    targetCode: string,
    conversion: CurrencyConversion
  ): Observable<CurrencyConversion> {
    return this._http.put<CurrencyConversion>(
      `${this._uri}/currency/${sourceCode}/conversionto/${targetCode}`,
      conversion
    );
  }

  orderStatusType(): Observable<OrderStatusType[]> {
    return this._http.get<OrderStatusType[]>(`${this._uri}/orderstatustype`);
  }

  createOrderStatusTypes(params: OrderStatusType): Observable<OrderStatusType> {
    return this._http.post<OrderStatusType>(
      `${this._uri}/orderstatustype`,
      params
    );
  }

  updateOrderStatusType(
    id: number,
    params: OrderStatusType
  ): Observable<OrderStatusType> {
    return this._http.put<OrderStatusType>(
      `${this._uri}/orderstatustype/${id}`,
      params
    );
  }

  deleteOrderStatusType(id: number) {
    return this._http.delete(`${this._uri}/orderstatustype/${id}`);
  }

  notifications(): Observable<NotificationSetting[]> {
    return this._http.get<NotificationSetting[]>(`${this._uri}/notifications`);
  }

  updateNotifications(
    params: NotificationSetting[]
  ): Observable<NotificationSetting[]> {
    return this._http.post<NotificationSetting[]>(
      `${this._uri}/notifications`,
      params
    );
  }

  tax(): Observable<Address> {
    return this._http.get<Address>(`${this._uri}/tax`);
  }

  addresses(): Observable<Address[]> {
    return this._http.get<Address[]>(`${this._uri}/addresses`);
  }

  address(id: number): Observable<Address> {
    return this._http.get<Address>(`${this._uri}/addresses/${id}`);
  }

  updateAddresses(id: number, address: Address): Observable<Address> {
    return this._http.put<Address>(`${this._uri}/addresses/${id}`, address);
  }

  createAddress(address: Address): Observable<Address> {
    return this._http.post<Address>(`${this._uri}/addresses`, address);
  }

  deleteAddress(id: number) {
    return this._http.delete(`${this._uri}/addresses/${id}`);
  }

  postMessage(message: string, subject?: string, body?: string) {
    if ((subject && !body) || (!subject && body)) {
      throw new Error('Subject + Body are required when one is provided');
    }

    let uri = `${this._uri}/notification`;

    if (!subject) {
      uri += `/bus?message=${message}`;
    } else {
      uri += `?message=${message}&subject=${subject}&body=${body}`;
    }

    return this._http.post(uri, {});
  }

  getAppCache(key: string, name: string) {
    return this._http.get(`${this._uri}/appcache/${key}/${name}`);
  }

  getCache(key: string, name: string) {
    return this._http.get(`${this._uri}/cache/${key}/${name}`);
  }

  taskSetting(id: number) {
    return this._http.get(`${this._uri}/settings/tasks/${id}`);
  }

  tasksSettings(): Observable<DefaultTaskSetting[]> {
    return this._http.get<DefaultTaskSetting[]>(`${this._uri}/tasks`);
  }

  manualTaskSettings() {
    return this._http.get<DefaultTaskSetting[]>(`${this._uri}/tasks/manual`);
  }

  createTasksSettings(task: DefaultTaskSetting) {
    return this._http.post(`${this._uri}/tasks`, task);
  }

  deleteTaskSetting(id: number) {
    return this._http.delete(`${this._uri}/tasks/${id}`);
  }

  getValidationList(): Observable<TenantValidationModel[]> {
    return this._http.get<TenantValidationModel[]>(
      `${this._uri}/validation/list`
    );
  }

  getCustomValidationList(): Observable<CustomTenantValidationModel[]> {
    return this._http.get<CustomTenantValidationModel[]>(
      `${this._uri}/validation/custom`
    );
  }

  addCustomValidation(type: string, orderType: string): Observable<void> {
    return this._http.post<void>(
      `${this._uri}/validation/custom/${type}/${orderType}`,
      {}
    );
  }

  deleteCustomValidation(type: string, orderType: string): Observable<void> {
    return this._http.delete<void>(
      `${this._uri}/validation/custom/${type}/${orderType}`
    );
  }

  updateValidationList(
    validation: TenantValidationModel[]
  ): Observable<TenantValidationModel[]> {
    return this._http.post<TenantValidationModel[]>(
      `${this._uri}/validation`,
      validation
    );
  }

  customValidations() {
    return this._http.get(`${this._uri}/settings/validation/custom`);
  }

  createValidation(key: string, isRequired: boolean, orderType: string) {
    return this._http.post(
      `${this._uri}/settings/validation/${key}/${isRequired}/${orderType}`,
      {}
    );
  }

  deleteValidation(key: string, orderType: string) {
    return this._http.delete(
      `${this._uri}/settings/validation/${key}/${orderType}`
    );
  }

  setupAiaValidation() {
    return this._http.post(`${this._uri}/validation/setup/aia`, {});
  }

  setupTailorValidation() {
    return this._http.post(`${this._uri}/validation/setup/tailor`, {});
  }

  clearValidation() {
    return this._http.post(`${this._uri}/validation/clear`, {});
  }

  editCustomValidation(type: string, orderType: string) {
    return this._http.post(
      `${this._uri}/validation/custom/${type}/${orderType}`,
      {}
    );
  }

  getAllEmailTemplates() {
    return this._http.get<OrderEmailTemplate[]>(
      `${this._uri}/orderemailtemplate`
    );
  }

  getEmailTemplateByType(type: string) {
    return this._http.get<OrderEmailTemplate>(
      `${this._uri}/orderemailtemplate/${type}`
    );
  }

  getEncoreEmailTemplateByType(type: string) {
    return this._http.get<OrderEmailTemplate>(
      `${this._uri}/orderemailtemplate/${type}/encore`
    );
  }

  getEmailTemplatesAsMap(): Observable<EmailTemplates> {
    return this.getAllEmailTemplates().pipe(
      map((emailTemplates) =>
        emailTemplates.reduce(
          (accumulator, emailTemplate) => {
            accumulator[emailTemplate.Type] = emailTemplate;
            return accumulator;
          },
          <EmailTemplates>{}
        )
      )
    );
  }

  updateEmailTemplate(emailTemplate: OrderEmailTemplate) {
    return this._http.post(`${this._uri}/orderemailtemplate`, emailTemplate);
  }

  getDefaultEmailTemplate(type: OrderEmailTemplateType) {
    return this._http.get<OrderEmailTemplate>(
      `${this._uri}/orderemailtemplate/default/${type}`
    );
  }

  getEmailTemplateMergedFields() {
    // We prolly don't need to load merged fields every time they're requested.
    // Email templates have reasons to change, for instance, if they updated by the same user
    // in another tab. That's why we always load them from the API and never cache them.
    if (this._cachedMergedFields !== null) {
      return of(this._cachedMergedFields);
    }

    return this._http
      .get<
        Record<OrderEmailTemplateType, OrderEmailMergedField[]>
      >(`${this._uri}/orderemailmergedfields`)
      .pipe(
        map((mergedFields) => {
          // https://github.com/asi/orders.asicentral.com/blob/917347ce9770f263eee6ca72ef813c10ed00af71/src/ESP.Web/App/modules/settings/config/settings.routes.js#L169-L170
          if (mergedFields[OrderEmailTemplateType.Order]) {
            mergedFields[OrderEmailTemplateType.SalesOrder] =
              mergedFields[OrderEmailTemplateType.Order];
          }
          return (this._cachedMergedFields = mergedFields);
        })
      );
  }

  updateParentTenantAuth(value: string) {
    return this._http.post(`${this._uri}/parenttenantauth/${value}`, {});
  }

  parentTenantAuth() {
    return this._http.get(`${this._uri}/parenttenantauth`);
  }

  addWhitelabelSettings(option: string) {
    return this._http.get(`${this._uri}/whitelabel/${option}`);
  }

  whitelabelSettings() {
    return this._http.get(`${this._uri}/whitelabel`);
  }

  getTenantNames() {
    return this._http.post(`${this._uri}/tenantnames/aia`, {});
  }

  addCreditStatus(option: string) {
    return this._http.post(`${this._uri}/creditstatuses?option=${option}`, {});
  }

  addSuperuserRole() {
    return this._http.post(`${this._uri}/superuserrole`, {});
  }

  deleteSuperuserRole() {
    return this._http.delete(`${this._uri}/superuserrole`);
  }

  isAia() {
    return this._http.get(`${this._uri}/tenant/isAia`);
  }

  isLapine() {
    return this._http.get(`${this._uri}/tenant/isLapine`);
  }

  createTailorApp() {
    return this._http.post(`${this._uri}/Tailor/createApp`, {});
  }

  enableTailorLapine() {
    return this._http.post(`${this._uri}/Tailor/enable/lapine`, {});
  }

  enableTailor() {
    return this._http.post(`${this._uri}/Tailor/enable/test`, {});
  }

  enableTailorOrder() {
    return this._http.post(`${this._uri}/Tailer/enable/test/order`, {});
  }

  enableTailorLineItem() {
    return this._http.post(`${this._uri}/Tailor/enable/test/lineitem`, {});
  }

  enableTailorProductVariantLineItem() {
    return this._http.post(
      `${this._uri}/Tailor/enable/test/productvariantlineitem`,
      {}
    );
  }

  deleteTailorModel(model: string) {
    return this._http.delete(`${this._uri}/Tailor/enable/test/${model}`);
  }

  disableTailor() {
    return this._http.post(`${this._uri}/Tailor/disable`, {});
  }

  allowEditOrderNumber(): Observable<AllowEditOrderNumberSetting> {
    return this._http.get<AllowEditOrderNumberSetting>(
      `${this._uri}/company_profile.allow_edit_order_number`
    );
  }

  updateAllowEditOrderNumber(
    payload: AllowEditOrderNumberSetting
  ): Observable<AllowEditOrderNumberSetting> {
    return this._http.put<AllowEditOrderNumberSetting>(
      `${this._uri}/company_profile.allow_edit_order_number`,
      payload
    );
  }

  getPVListMaxSupplierSetting() {
    return this._http.get<Setting>(
      `${this._uri}/preferred_vendors.max_suppliers_list`
    );
  }
}
