import { createPropertySelectors, createSelector } from '@ngxs/store';

import {
  PaymentProviderName,
  PaymentProvidersSettingsType,
  type AuthorizenetSettings,
  type PaymentMethodInfo,
  type PayPalSettings,
  type PromoPaymentSettings,
  type StripeSettings,
} from '@esp/payments/types-payment';
import type { Setting } from '@esp/settings/types';

import {
  PaymentProvidersSettingsState,
  type PaymentProvidersSettingsStateModel,
} from '../states';
import { createPaymentMethodInfo } from '../utils';

export namespace PaymentProvidersSettingsQueries {
  export const getSettings = createSelector(
    [PaymentProvidersSettingsState],
    (state: PaymentProvidersSettingsStateModel) => state.settings
  );

  export const settingsLoadingStatus = createSelector(
    [PaymentProvidersSettingsState],
    (state: PaymentProvidersSettingsStateModel) => state.loading
  );

  export const { stripeInformation } =
    createPropertySelectors<PaymentProvidersSettingsStateModel>(
      PaymentProvidersSettingsState
    );

  export const stripeOAuthLink = createSelector(
    [stripeInformation],
    (stripeInfo) => stripeInfo?.stripeOAuthLink?.replace(/"/g, '')
  );

  export const stripePublicKey = createSelector(
    [stripeInformation],
    (stripeInfo) => stripeInfo?.stripePublicKey?.replace(/"/g, '')
  );

  export const connectProviderOperation = createSelector(
    [PaymentProvidersSettingsState],
    (state: PaymentProvidersSettingsStateModel) =>
      state.connectProviderOperation
  );

  export const paypalSettings = createSelector(
    [getSettings],
    (settings): Omit<PayPalSettings, 'Captcha'> => {
      return {
        AccountId:
          settings[PaymentProvidersSettingsType.PayPalAccountId]?.Value,
      };
    }
  );

  export const stripeSettings = createSelector(
    [getSettings],
    (settings): StripeSettings => {
      return {
        AccountId:
          settings[PaymentProvidersSettingsType.StripeAccountId]?.Value,
        PublicKey:
          settings[PaymentProvidersSettingsType.StripePublicClientKey]?.Value,
        PrivateKey:
          settings[PaymentProvidersSettingsType.StripeSecretKey]?.Value,
        AcceptsCreditCards: isTrue(
          settings[PaymentProvidersSettingsType.StripeAcceptsCreditCards]?.Value
        ),
        IsPortalDefault:
          settings[PaymentProvidersSettingsType.PortalDefault]?.Value ===
          PaymentProviderName.Stripe,
      };
    }
  );

  export const authorizenetSettings = createSelector(
    [getSettings],
    (settings): AuthorizenetSettings => {
      return {
        PublicClientKey:
          settings[PaymentProvidersSettingsType.AuthorizenetPublicClientKey]
            ?.Value,
        ApiLoginKey:
          settings[PaymentProvidersSettingsType.AuthorizenetApiLoginKey]?.Value,
        TransactionKey:
          settings[PaymentProvidersSettingsType.AuthorizenetTransactionKey]
            ?.Value,
        AcceptsCreditCards: isTrue(
          settings[PaymentProvidersSettingsType.AuthorizenetAcceptsCreditCards]
            ?.Value
        ),
        ElectronicFundsTransferAcceptance: isTrue(
          settings[
            PaymentProvidersSettingsType.AuthorizenetElectronicFundsTransfer
          ]?.Value
        ),
        IsPortalDefault:
          settings[PaymentProvidersSettingsType.PortalDefault]?.Value ===
          PaymentProviderName.Authorizenet,
      };
    }
  );

  export const promoPaymentSettings = createSelector(
    [getSettings],
    (settings): PromoPaymentSettings => {
      return {
        PublicKey:
          settings[PaymentProvidersSettingsType.PromoPaymentPublicKey]?.Value,
        PrivateKey:
          settings[PaymentProvidersSettingsType.PromoPaymentSecurityKey]?.Value,
        AcceptsCreditCards: isTrue(
          settings[PaymentProvidersSettingsType.PromoPaymentAcceptsCreditCards]
            ?.Value
        ),
        ElectronicFundsTransferAcceptance: isTrue(
          settings[
            PaymentProvidersSettingsType.PromoPaymentElectronicFundsTransfer
          ]?.Value
        ),
        IsPortalDefault:
          settings[PaymentProvidersSettingsType.PortalDefault]?.Value ===
          PaymentProviderName.PromoPayment,
      };
    }
  );

  export const promoPaymentPaymentMethodInfoMap = createSelector(
    [promoPaymentSettings],
    (promoPaymentSettings): Record<string, PaymentMethodInfo> => {
      return Object.keys(promoPaymentSettings).reduce(
        createPaymentMethodInfo,
        {}
      );
    }
  );

  export const paypalIsConnected = createSelector(
    [paypalSettings],
    (paypalSettings: PayPalSettings) => {
      return !!paypalSettings.AccountId;
    }
  );
  export const paypalIsConfigured = createSelector(
    [paypalSettings],
    (paypalSettings: PayPalSettings) => {
      return !!paypalSettings.AccountId;
    }
  );

  export const stripePaymentMethodInfoMap = createSelector(
    [stripeSettings],
    (stripeSettings): Record<string, PaymentMethodInfo> => {
      return Object.keys(stripeSettings).reduce(createPaymentMethodInfo, {});
    }
  );

  export const stripeIsConnected = createSelector(
    [stripeSettings],
    (stripeSettings) => {
      return !!stripeSettings.AccountId;
    }
  );

  export const stripeIsConfigured = createSelector(
    [stripeSettings],
    (stripeSettings: StripeSettings) => {
      const isConfigured =
        Boolean(stripeSettings.AccountId) ||
        (Boolean(stripeSettings.PublicKey) &&
          Boolean(stripeSettings.PrivateKey));

      return isConfigured && stripeSettings.AcceptsCreditCards;
    }
  );

  export const authorizenetMethodInfoMap = createSelector(
    [authorizenetSettings],
    (authorizenetSettings): Record<string, PaymentMethodInfo> => {
      return Object.keys(authorizenetSettings).reduce(
        createPaymentMethodInfo,
        {}
      );
    }
  );

  export const currentPortalDefault = createSelector(
    [getSettings],
    (settings: Record<string, Setting>) =>
      settings[PaymentProvidersSettingsType.PortalDefault]?.Value || undefined
  );

  export const authorizenetIsConnected = createSelector(
    [authorizenetSettings],
    (authorizenetSettings: AuthorizenetSettings) => {
      return !!authorizenetSettings.PublicClientKey;
    }
  );

  export const authorizenetIsConfigured = createSelector(
    [authorizenetSettings],
    (authorizenetSettings: AuthorizenetSettings) => {
      return (
        !!authorizenetSettings.PublicClientKey &&
        !!authorizenetSettings.ApiLoginKey &&
        (authorizenetSettings.AcceptsCreditCards ||
          authorizenetSettings.ElectronicFundsTransferAcceptance)
      );
    }
  );

  export const promoPaymentIsConnected = createSelector(
    [promoPaymentSettings],
    (promoPaymentSettings: PromoPaymentSettings) => {
      return !!promoPaymentSettings.PublicKey;
    }
  );

  export const promoPaymentIsConfigured = createSelector(
    [promoPaymentSettings],
    (promoPaymentSettings: PromoPaymentSettings) => {
      return (
        !!promoPaymentSettings.PrivateKey &&
        !!promoPaymentSettings.PublicKey &&
        (promoPaymentSettings.AcceptsCreditCards ||
          promoPaymentSettings.ElectronicFundsTransferAcceptance)
      );
    }
  );

  export const hasConfiguredPaymentProvider = createSelector(
    [
      paypalIsConfigured,
      stripeIsConfigured,
      authorizenetIsConfigured,
      promoPaymentIsConfigured,
      currentPortalDefault,
    ],
    (
      paypalIsConfigured,
      stripeIsConfigured,
      authorizenetIsConfigured,
      promoPaymentIsConfigured,
      defaultPaymentProvider
    ) => {
      return (
        ([
          stripeIsConfigured,
          authorizenetIsConfigured,
          promoPaymentIsConfigured,
        ].some((value) => value) &&
          !!defaultPaymentProvider) ||
        paypalIsConfigured
      );
    }
  );

  export const isAnyPrimaryProvider = createSelector(
    [stripeIsConnected, authorizenetIsConnected, promoPaymentIsConnected],
    (stripeIsConnected, authorizenetIsConnected, promoPaymentIsConnected) => {
      return (
        stripeIsConnected || authorizenetIsConnected || promoPaymentIsConnected
      );
    }
  );

  // only providers that can be mark as primary included [PayPal removed]
  export const connectedProvidersNames = createSelector(
    [stripeIsConnected, authorizenetIsConnected, promoPaymentIsConnected],
    (stripeIsConnected, authorizenetIsConnected, promoPaymentIsConnected) =>
      [
        stripeIsConnected && PaymentProviderName.Stripe,
        authorizenetIsConnected && PaymentProviderName.Authorizenet,
        promoPaymentIsConnected && PaymentProviderName.PromoPayment,
      ].filter(Boolean)
  );

  const isTrue = (value: string) => {
    return value?.toLowerCase() === 'true';
  };
}
