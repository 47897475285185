import { Injectable } from '@angular/core';
import { Action, State, type StateContext } from '@ngxs/store';
import { EMPTY } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { syncLoadProgress, syncOperationProgress } from '@cosmos/state';
import type { OperationStatus } from '@cosmos/types-common';
import { ToastActions } from '@cosmos/types-notification-and-toast';
import {
  PaymentProviderName,
  PaymentProvidersSettingsType,
} from '@esp/payments/types-payment';
import type { Setting } from '@esp/settings/types';

import { PaymentProvidersSettingsActions } from '../actions';
import { PaymentProvidersSettingsService } from '../services/payment-providers-settings.service';

import { TOAST_MESSAGES } from './toast-messages';

export interface PaymentProvidersSettingsStateModel {
  settings: Record<string, Setting>;
  connectProviderOperation?: OperationStatus;
  loading?: OperationStatus;
  stripeInformation?: {
    stripeOAuthLink?: string;
    stripePublicKey?: string;
  };
}
type LocalStateContext = StateContext<PaymentProvidersSettingsStateModel>;

@State<PaymentProvidersSettingsStateModel>({
  name: 'paymentProvidersSettings',
  defaults: {
    settings: {},
  },
})
@Injectable()
export class PaymentProvidersSettingsState {
  constructor(private readonly _service: PaymentProvidersSettingsService) {}

  @Action(PaymentProvidersSettingsActions.LoadSettings)
  private _loadSettings(ctx: LocalStateContext) {
    return this._service.get().pipe(
      tap((data) => {
        const settings: Record<string, Setting> = {};
        data.forEach((setting) => {
          settings[setting.Type] = setting;
        });
        ctx.patchState({ settings });
      }),
      syncLoadProgress(ctx, {
        errorMessage: 'Could not successfully retrieve settings from profile.',
      }),
      catchError(() => EMPTY)
    );
  }

  @Action(PaymentProvidersSettingsActions.ConnectProvider)
  private _connectProvider(
    ctx: LocalStateContext,
    { payload, providerName }: PaymentProvidersSettingsActions.ConnectProvider
  ) {
    ctx.getState();
    return this._service
      .updateSettingBulk(
        this._adjustPrimaryProviderSettings(
          payload as Setting[],
          providerName,
          true,
          ctx
        )
      )
      .pipe(
        syncOperationProgress(ctx, 'connectProviderOperation'),
        tap((data) => {
          const settings: Record<string, Setting> = {};
          data.forEach((setting) => {
            settings[setting.Type] = setting;
          });
          ctx.patchState({
            settings: { ...ctx.getState().settings, ...settings },
          });

          ctx.dispatch(
            new ToastActions.Show(TOAST_MESSAGES.CONNECT_PROVIDER(providerName))
          );
        }),
        catchError(() => {
          ctx.dispatch(new ToastActions.Show(TOAST_MESSAGES.CONNECT_ERROR()));
          return EMPTY;
        })
      );
  }

  @Action(PaymentProvidersSettingsActions.DisconnectProvider)
  private _disconnectProvider(
    ctx: LocalStateContext,
    {
      payload,
      providerName,
    }: PaymentProvidersSettingsActions.DisconnectProvider
  ) {
    return this._service
      .updateSettingBulk(
        this._adjustPrimaryProviderSettings(
          payload as Setting[],
          providerName,
          false,
          ctx
        )
      )
      .pipe(
        syncOperationProgress(ctx, 'connectProviderOperation'),
        tap((data) => {
          const settings: Record<string, Setting> = {};
          data.forEach((setting) => {
            settings[setting.Type] = setting;
          });
          ctx.patchState({
            settings: { ...ctx.getState().settings, ...settings },
          });

          ctx.dispatch(
            new ToastActions.Show(
              TOAST_MESSAGES.DISCONNECT_PROVIDER(providerName)
            )
          );
        }),
        catchError(() => {
          ctx.dispatch(
            new ToastActions.Show(TOAST_MESSAGES.DISCONNECT_ERROR())
          );
          return EMPTY;
        })
      );
  }

  @Action(PaymentProvidersSettingsActions.RetrieveOAuthLinkForStripe)
  private _retrieveOAutLinkForStripe(ctx: LocalStateContext) {
    return this._service.retrieveOAuthLink().pipe(
      tap((res) => {
        ctx.patchState({
          stripeInformation: {
            ...ctx.getState().stripeInformation,
            stripeOAuthLink: res,
          },
        });
      })
    );
  }

  @Action(PaymentProvidersSettingsActions.ConnectStripeAccount)
  private _connectStripeAccount(
    ctx: LocalStateContext,
    { code }: PaymentProvidersSettingsActions.ConnectStripeAccount
  ) {
    return this._service
      .connectStripeAccount(code)
      .pipe(
        tap(() =>
          ctx.dispatch(
            new PaymentProvidersSettingsActions.ConnectProvider(
              [] as Setting[],
              PaymentProviderName.Stripe
            )
          )
        )
      );
  }

  private _adjustPrimaryProviderSettings(
    settings: Setting[],
    providerName: string,
    isConnecting: boolean,
    ctx: LocalStateContext
  ): Setting[] {
    const ARRAY_LENGTH_ON_CONNECTING = 0 as const; // use when user connects first provider
    const ARRAY_LENGTH_ON_DISCONNECTING = 2 as const; // use when user disconnects one of two providers
    const connectedProvidersNames = this._getConnectedProvidersNames(ctx);
    const primaryValue =
      connectedProvidersNames.length ===
      (isConnecting
        ? ARRAY_LENGTH_ON_CONNECTING
        : ARRAY_LENGTH_ON_DISCONNECTING)
        ? {
            Type: PaymentProvidersSettingsType.PortalDefault,
            Value: connectedProvidersNames.length
              ? connectedProvidersNames.find((name) => name !== providerName)
              : providerName,
          }
        : undefined;
    return (primaryValue ? [...settings, primaryValue] : settings) as Setting[];
  }

  private _getConnectedProvidersNames(
    ctx: LocalStateContext
  ): PaymentProviderName[] {
    const state = ctx.getState();
    const stripeIsConnected =
      !!state.settings[PaymentProvidersSettingsType.StripeAccountId]?.Value;
    const authorizenetIsConnected =
      !!state.settings[PaymentProvidersSettingsType.AuthorizenetPublicClientKey]
        ?.Value;
    const promoPaymentIsConnected =
      !!state.settings[PaymentProvidersSettingsType.PromoPaymentPublicKey]
        ?.Value;
    return [
      stripeIsConnected && PaymentProviderName.Stripe,
      authorizenetIsConnected && PaymentProviderName.Authorizenet,
      promoPaymentIsConnected && PaymentProviderName.PromoPayment,
    ].filter(Boolean) as PaymentProviderName[];
  }
}
