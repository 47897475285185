import { inject, Injectable } from '@angular/core';
import {
  Action,
  State,
  type NgxsAfterBootstrap,
  type StateContext,
} from '@ngxs/store';
import { catchError, of, take, tap } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { syncLoadProgress, syncOperationProgress } from '@cosmos/state';
import type {
  ModelWithLoadingStatus,
  OperationStatus,
} from '@cosmos/types-common';
import { ToastActions } from '@cosmos/types-notification-and-toast';
import { handleNgxsError } from '@cosmos/util-common';
import { AuthFacade } from '@esp/auth/data-access-auth';
import type {
  CompanyProfile,
  CompanyProfileSetting,
} from '@esp/settings/types';

import { CompanyProfileActions, SettingsActions } from '../actions';
import { CompanyProfileService } from '../services';

import { TOAST_MESSAGES } from './toast-messages';

const allowLineItemStatusKey: CompanyProfile = 'allow_line_item_status';
export interface CompanyProfileStateModel extends ModelWithLoadingStatus {
  settings: CompanyProfileSetting | null;
  updateStatus?: OperationStatus;
}

type LocalStateContext = StateContext<CompanyProfileStateModel>;

@State<CompanyProfileStateModel>({
  name: 'companyProfile',
  defaults: {
    settings: null,
  },
})
@Injectable()
export class CompanyProfileState implements NgxsAfterBootstrap {
  private readonly _authFacade = inject(AuthFacade);
  private readonly _companyProfileService = inject(CompanyProfileService);

  ngxsAfterBootstrap(ctx: LocalStateContext): void {
    this._authFacade
      .getUser()
      .pipe(take(1))
      .subscribe(() => ctx.dispatch(new CompanyProfileActions.Load()));
  }

  @Action(CompanyProfileActions.Load)
  load(ctx: LocalStateContext) {
    return this._companyProfileService.get().pipe(
      tap((settings) => ctx.patchState({ settings })),
      syncLoadProgress(ctx, {
        errorMessage: 'Could not successfully retrieve company profile.',
      })
    );
  }

  @Action(CompanyProfileActions.Update)
  update(ctx: LocalStateContext, { payload }: CompanyProfileActions.Update) {
    return this._companyProfileService
      .update({ ...ctx.getState().settings!, ...payload })
      .pipe(
        syncOperationProgress(ctx, 'updateStatus'),
        tap((settings) => ctx.patchState({ settings })),
        catchError(() => {
          if (payload.Signature) {
            ctx.dispatch(
              new ToastActions.Show(TOAST_MESSAGES.UPDATE_SIGNATURE_ERROR())
            );
            return of();
          }

          const keys = Object.keys(payload);

          if (
            keys.includes('DefaultSalesperson') ||
            keys.includes('AllowReorderTypes') ||
            keys.includes('DisablePayment') ||
            keys.includes('OrderInquiryOption')
          ) {
            ctx.dispatch(
              new ToastActions.Show(
                TOAST_MESSAGES.UPDATE_COMPANY_PROFILE_SETTINGS_ERROR()
              )
            );
          }

          return of();
        })
      );
  }

  @Action(CompanyProfileActions.UpdateAllowLineItemStatuses)
  updateAllowLineItemStatuses(
    ctx: LocalStateContext,
    { payload }: CompanyProfileActions.UpdateAllowLineItemStatuses
  ) {
    return ctx
      .dispatch(
        new SettingsActions.UpdateSetting(
          allowLineItemStatusKey,
          { Value: payload },
          'company_profile'
        )
      )
      .pipe(
        syncOperationProgress(ctx, 'updateStatus'),
        handleNgxsError(() => {
          ctx.dispatch(
            new ToastActions.Show(
              TOAST_MESSAGES.UPDATE_COMPANY_PROFILE_SETTINGS_ERROR()
            )
          );
        }),
        switchMap(() => ctx.dispatch(new CompanyProfileActions.Load()))
      );
  }
}
